<template>
  <footer>
    <div class="container footer">
        <div class="col-4">
            <a href="#" class="logo">
                <div class="logo-img"></div>
                <div class="logo-text"> Top<span>it</span></div>
            </a>
            <p>Остались вопросы или есть предложение по сотрудничеству?<br> Отправьте письмо на почту<br>info@top-it72.ru
            </p>
        </div>
        <div class="col-4">
            <div class="logo-text">Услуги</div>
            <a @click="$router.push({'path'  :'/sites/'})" class="menu-item">Web-разработка</a>
            <a @click ="$router.push({'path'  :'/outsorsing/'})" class="menu-item">IT-аутсорсинг</a>
            <a @click ="$router.push({'path'  :'/infobez/'})" class="menu-item">Информационная безопасность</a>
        </div>"

        <div class="col-4">
            <div class="logo-text">Контакты</div>
            <p><i class="fas fa-map-marker-alt"></i> г. Тюмень, ул. Калинина, <br>д. 62 оф. 203</p>
            <a :href="'tel:'+phoneLink" class="phone-link"><i class="fas fa-phone-alt"></i>{{phone}}</a>
        </div>
    </div>
</footer>
</template>

<script>
import con from '../constatnts'
export default {
    data() {
        return {
            phone: con.phone,
            phoneLink: con.phoneLink
        }
    },
}
</script>

<style>

</style>