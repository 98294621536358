<template>
<div class="app">
    <header class="header-services" style="background: url('https://top-it72.ru/img/outsorsing.jpg') no-repeat center center /cover fixed ">
        <div class="header-overlay">
            <h2>IT-аутсорсинг</h2>

        </div>
        <Menu />
    </header>
    <section class="dark">
        <div class="container">
            <div class="zagl">
                <h2>Что такое IT-аутсорсинг от TOP-IT?</h2>
                <div class="back-text h2">IT-аутсорсинг</div>
            </div>
            <div class="flex" style="margin-top: 50px">
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="far fa-clock"></i>
                            <h3 class="flipper-h">Оперативность</h3>
                            <p class="flipper-p">Отреагируем на заявку в течение 15 минут. В случае необходимости приедем в течение часа</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c1.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Очень быстро!</h3>
                                <p class="flipper-p">Скорость реакции - это про нас! Знаем как важно чтоб все работало здесь и сейчас. </p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-dollar-sign"></i>
                            <h3 class="flipper-h">Выгодно</h3>
                            <p class="flipper-p">Стоимость IT-аутсорсинга выгоднее чем держать штатного администратора или пользоваться разовыми услугами</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c2.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Фиксированная цена!</h3>
                                <p class="flipper-p">А еще мы гарантируем что, цена, указанная в договоре окончательная, и
                                    не вырастет в процессе работы.</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-brain"></i>
                            <h3 class="flipper-h">Профессионализм</h3>
                            <p class="flipper-p">Мы - команда профессионалов. Решим любую поставленную задачу, предложим разные варианты.</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c3.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h" style="text-decoration: line-through 5px">Невозможно!</h3>
                                <p class="flipper-p">Для нас "невозможно" - слово-которое-нельзя-называть. Найдем решение. Всегда.</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-wrench"></i>
                            <h3 class="flipper-h">Гарантия</h3>
                            <p class="flipper-p">Мы уверены в своей работе, поэтому не ограничиваем количество выездов</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c4.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Безлимит!</h3>
                                <p class="flipper-p">Приедем столько раз, сколько это потребуется. Для вас это ничего не стоит</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-laptop"></i>
                            <h3 class="flipper-h">Подменное оборудование</h3>
                            <p class="flipper-p">Предоставим подменное оборудование, в случае если ваша техника вышла из строя</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/s2.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Бесплатно!</h3>
                                <p class="flipper-p">Для клиентов на абонентском обслуживании эта услуга уже включена в стоимость</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-clipboard-list"></i>
                            <h3 class="flipper-h">Отчетность</h3>
                            <p class="flipper-p">По требованию заказчика предоставим отчет о проделанной работе за требуемый период</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/s3.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Все прозрачно!</h3>
                                <p class="flipper-p">
                                    <p>Нам нечего скрывать, контролируйте ход работы в личном кабинете</p>
                                    <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section style="padding-top: 50px">
        <div class="container">
            <div class="zagl">
                <h2>Цены на услуги</h2>
                <div class="back-text h2">Цены</div>
            </div>
            <div class="prices-holder">
                <div class="price-wrapper">
                    <i class="fas fa-paper-plane"></i>
                    <div class="plan-name">Удаленка</div>
                    <div class="plan-descr">В стоимость включена безлимитная удаленная техническая поддержка, выезды оплачиваются отдельно </div>
                    <p class="num-header">Рабочие места</p>
                    <el-input-number @change="schit" :min="0" v-model="comps" placeholder="0"></el-input-number>
                    <p class="num-header">Серверы</p>
                    <el-input-number @change="schit" :min="0" v-model="servers" placeholder="0"></el-input-number>
                    <div class="plan-price plan-out">{{compSum*0.5}}₽/месяц</div>
                    <div @click="zakaz('Удаленка')" onclick="zakaz()" class="plan-buy buy-out">Заказать</div>
                </div>
                <div class="price-wrapper">
                    <i class="fas fa-paper-plane"></i>
                    <div class="plan-name">Стандарт</div>
                    <div class="plan-descr">Комплексное обслуживание. Выезды и удаленная помошь в рабочее время не ограничены </div>
                    <p class="num-header">Рабочие места</p>
                    <el-input-number @change="schit" :min="0" v-model="comps" placeholder="0"></el-input-number>
                    <p class="num-header">Серверы</p>
                    <el-input-number @change="schit" :min="0" v-model="servers" placeholder="0"></el-input-number>
                    <div class="plan-price plan-out">{{compSum}}₽/месяц</div>
                    <div @click="zakaz('Стандарт')" onclick="zakaz()" class="plan-buy buy-out">Заказать</div>
                </div>
                <div class="price-wrapper">
                    <i class="fas fa-paper-plane"></i>
                    <div class="plan-name">24/7</div>
                    <div class="plan-descr">Тариф для самых требовательных. На связи 24/7. Приедем в течение часа в любое время</div>
                    <p class="num-header">Рабочие места</p>
                    <el-input-number @change="schit" :min="0" v-model="comps" placeholder="0"></el-input-number>
                    <p class="num-header">Серверы</p>
                    <el-input-number @change="schit" :min="0" v-model="servers" placeholder="0"></el-input-number>
                    <div class="plan-price plan-out">{{compSum*1.7}}₽/месяц</div>
                    <div @click="zakaz('24/7')" class="plan-buy buy-out">Заказать</div>
                </div>
            </div>
        </div>
    </section>
    <Contacts />
    <el-dialog width="600px" :visible.sync="dialog" :title="'Заказать услугу - ' + form.tariff">
        <el-form class="form-zakaz" ref="form" :model="form" label-width="220px">
            <el-form-item required label="Как к вам обращаться?">
                <el-input v-model="form.name"> </el-input>
            </el-form-item>
            <el-form-item required label="Номер телефона">
                <el-input v-model="form.phone"> </el-input>
            </el-form-item>
            <el-form-item label="Адрес эл. почты">
                <el-input v-model="form.mail"> </el-input>
            </el-form-item>
            <el-form-item label="Желаемое время для звонка">
                <el-date-picker v-model="form.dateTime" type="datetime">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="Комментарий">
                <el-input type="textarea" :rows="2" v-model="form.message">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button :loading="loading" class="plan-buy buy-out" @click="send">Отправить</el-button>
            </el-form-item>

        </el-form>
    </el-dialog>
</div>
</template>

<script>
import Contacts from '../components/Contacts.vue'
import axios from 'axios'
export default {
    components: { Contacts },
    data() {
        return {
            loading: false,
            comps: 5,
            servers: 1,
            compSum: 0,
            dialog: false,
            form: {
                name: '',
                dateTime: '',
                message: '',
                tariff: '',
                price: '',
                comps: '',
                servers: '',
                phone: '',
                mail: ''
            }
        }
    },
    methods: {
        send() {
            this.loading = true;
            let data = new FormData;
            data.append('type', 'outsors');
            data.append('name', this.form.name);
            data.append('dateTime', this.form.dateTime);
            data.append('message', this.form.message);
            data.append('tariff', this.form.tariff);
            data.append('price', this.form.price);
            data.append('comps', this.form.comps);
            data.append('servers', this.form.servers);
            data.append('phone', this.form.phone);
            data.append('mail', this.form.mail);
            axios.post('mailer.php', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(this.loading=false, this.dialog=false)
        },
        zakaz(tariff) {
            this.form.tariff = tariff;
            this.form.price = this.compSum;
            this.form.comps = this.comps;
            this.form.servers = this.servers;
            this.dialog = true;
        },
        schit() {
            this.compSum = 0;
            for (let i = 0; i < this.comps; i++) {
                if (i < 5) {
                    this.compSum += 1000;
                } else if (5 <= i < 10) {
                    this.compSum += 900;
                } else if (10 <= i < 15) {
                    this.compSum += 850;
                } else if (15 <= i < 20) {
                    this.compSum += 800;
                } else if (20 <= i < 25) {
                    this.compSum += 800;
                } else {
                    this.compSum += 750;
                }
            }
            this.compSum += this.servers * 1500
        }
    },
    mounted() {
        this.schit();
        scrollTo(0, 0)
         document.title = "TOP-it | IT-аутсорсинг в Тюмени"
    },

}
</script>

<style>
@media (max-width: 600px){
    .el-form-item {
        display: flex!important;
        flex-direction: column;
    }
    .el-form-item__content{
        margin-left: 0!important;
        width: 100%;
    }
    .el-dialog{
        width: 100vw!important;
        margin: 0!important
    }
    .el-form-item__label{
        width: 100%!important;
        text-align: center!important;
    }
    .el-form-item__content{
        text-align: center !important;;
    }
}
.buy-out {
    bottom: 0;
}

.el-form-item__label {
    color: white;
}

.buy-out span {
    color: white !important
}

.form-zakaz {
    align-items: unset;
}

.buy-out {
    position: unset;
}

.plan-out {
    margin: 20px 0
}

.price-wrapper .el-input-number {
    background: transparent !important;
}

.price-wrapper input {
    background: transparent;
    color: white;
    font-weight: 600;
    font-size: 16px;
}

.price-wrapper .el-input-number span {
    background: #182C44;
}
</style>
