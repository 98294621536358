<template>
<div class="header-topline" id="menu">
    <a class="logo">
        <div class="logo-img"></div>
        <div class="logo-text">Top <span>it</span></div>
    </a>
    <nav>
        <div @click="burger=true" class="burger-button">
            <i class="el-icon-menu"></i>
        </div>
        <ul class="main-menu">
            <a v-for="item,i in menu" :key="i" @click="$router.push({'path': item.link})" class="menu-item">{{item.name}}</a>

        </ul>
    </nav>
    <transition name="slide">
        <div v-show="burger" class="drawer">
            

                <div class="side-menu">
                    <button @click="burger=false" type="button" aria-label="Close" class="el-dialog__headerbtn"><i class="el-dialog__close el-icon el-icon-close"></i></button>
                    <a class="logo">
                        <div class="logo-img"></div>
                        <div class="logo-text">Top <span>it</span></div>
                    </a>
                    <a v-for="item,i in menu" :key="i" @click="goRoute(item.link)" class="menu-item">{{item.name}}</a>

                </div>
            
        </div>
    </transition>
</div>
</template>

<script>
export default {
    methods: {
        goRoute(item) {

            this.$router.push({ 'path': item })
            this.burger = false
        }
    },
    data() {
        return {
            burger: false,
            menu: [{
                    'link': '/',
                    'name': 'Главная'
                },
                {
                    'link': '/sites',
                    'name': 'Web-разработка'
                },
                {
                    'link': '/outsorsing',
                    'name': 'IT-аутсорсинг'
                },
                {
                    'link': '/infobez',
                    'name': 'Информационная безопасность'
                },
            ]
        }
    },
}
</script>

<style>
.slide-enter-active,
.slide-leave-active {
    transition: opacity .3s;
}

.slide-enter,
.slide-leave-to

/* .fade-leave-active до версии 2.1.8 */
    {
    opacity: 0;
}

.drawer {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.411);
}

.side-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background: #1E3552;
    display: flex;
    flex-direction: column;
    padding: 50px 10px;
}

.side-menu .menu-item {
    margin: 5px 30px
}

.side-menu .logo {
    margin: 5px 30px
}
</style>
