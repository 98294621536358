<template>
  <div class="app">
    <header class="header-services" style="background: url('./img/infobez.jpg') no-repeat center center /cover fixed ">
        <div class="header-overlay">
            <h2>Информационная безопасность</h2>

        </div>
        <Menu />
    </header>
     <section class="dark">
        <div class="container">
            <div class="zagl">
                <h2>Что такое Информационная безопасность от TOP-IT?</h2>
                <div class="back-text h2">Безопасность</div>
            </div>
            <div class="flex" style="margin-top: 50px">
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-network-wired"></i>
                            <h3 class="flipper-h">Сетевая безопаность</h3>
                            <p class="flipper-p">Обнаружим и утраним ошибки в конфигурации сетевого оборудования</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c1.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Нет проникновениям!</h3>
                                <p class="flipper-p">Многие организации используют оборудования с настройками по умолчанию, проверим ваш роутер?</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-desktop"></i>
                            <h3 class="flipper-h">Безопасность рабочих мест</h3>
                            <p class="flipper-p">Проверим рабочие места пользователей на наличие шпионского ПО и оборудования</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c2.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Нет троянам!</h3>
                                <p class="flipper-p"> По статистике 68% процентов пользователей не следят за тем что именно они устанавливают</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-server"></i>
                            <h3 class="flipper-h">Безопасность серверов</h3>
                            <p class="flipper-p">Проверим сервера и службы на наличие ошибок конфигурирования</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c3.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h" >По умолчанию</h3>
                                <p class="flipper-p">Многие администраторы разворачивают системы по инструкциям из интернета. Это не всегда безопасно.</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-user-secret"></i>
                            <h3 class="flipper-h">Выявление инсайдеров</h3>
                            <p class="flipper-p">Расскажем чем занимаются сотрудники на рабочем месте, выявим пути утечки информации</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/c4.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Информация</h3>
                                <p class="flipper-p">Утечка важной информации может повлечь за собой как экономические, так и репутационные потери. Стоит подумать о безопасности уже сейчас</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-people-arrows"></i>
                            <h3 class="flipper-h">Социальная инженерия</h3>
                            <p class="flipper-p">Обучим сотрудников противостоять методам социальной инженерии</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/s2.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Пример</h3>
                                <p class="flipper-p">Бухгалтер открывает зараженное письмо, замаскированное под требование налоговой - злоумышленники получают доступ к ПК</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div class="flipper">
                        <div class="front">
                            <i class="fas fa-clipboard-list"></i>
                            <h3 class="flipper-h">Тестирование на проникновение</h3>
                            <p class="flipper-p">Проведем комплексную проверку на выявление всех возможных уязвимостей в инфраструктуре организации</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" style="background: url('https://top-it72.ru/img/s3.jpg') no-repeat center center / cover">
                            <div class="overlay-back">
                                <h3 class="flipper-h">Отчет</h3>
                                <p class="flipper-p">
                                    <p>Предоставим полный развернутый отчет со всеми уязвимостями и путями их решения</p>
                                    <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <Contacts />
  </div>
</template>

<script>
import Contacts from '../components/Contacts.vue'
export default {
    mounted() {
        scrollTo(0,0)
         document.title = "TOP-it | Информационная безопасность в Тюмени"
    },
  components: { Contacts },

}
</script>

<style>

</style>