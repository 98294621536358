import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sites from '../views/Sites.vue'
import Outsors from '../views/Outsors'
import Infobez from '../views/Infobez'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sites',
    name: 'Sites',
    component: Sites
  },
  {
    path: '/outsorsing',
    name: 'Outsorsing',
    component: Outsors
  },
  {
    path: '/infobez',
    name: 'Infobez',
    component: Infobez
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
