<template>
<section class="contact">
    <div class="overaly"></div>
    <div class="container">
        <div class="zagl">
            <h2>Обратная связь</h2>
            <div class="back-text h2">Контакты</div>
            <p class="contacts-p">Телефон: {{ourphone}}</p>
            <p class="contacts-p"> Почта: top-it@top-it72.ru</p>
            <p class="contacts-p">Оставьте свои контакты и мы с вами свяжемся!</p>
        </div>
        <el-form class="form">
            <div class="form-flex">
                <el-input type="text" v-model="name" placeholder="Имя" required></el-input>
                <el-input type="tel" v-model="phone" placeholder="Телефон" required></el-input>
            </div>
            <el-button class="menu-btn btn-send" @click="sendData" :loading="loading">{{btnText}}</el-button>
            <div id="inner"></div>
        </el-form>
    </div>
</section>
</template>

<script>
import phones from '../constatnts'
import axios from 'axios';
export default {
    data() {
        return {
            btnText: 'Отправить',
            loading: false,
            name: '',
            phone: '',
            ourphone: phones.phone
        }
    },
    methods: {
        sendData() {
            let data = new FormData;
            data.append('type', 'contacts')
            data.append('name', this.name)
            data.append('phone', this.phone)
            this.btnText = 'Отправляется'
            this.loading = true;
            axios.post('mailer.php', data).then(response => { console.log(response.data);
                this.btnText = 'Отправлено';
                this.loading = false })
        }
    },
}
</script>

<style>
.contacts-p{
    margin: 0;
}
.btn-send {
    display: block;
    width: 95%;
}

.form-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.el-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-flex .el-input {
    max-width: 180px;
    margin: 10px;
}
</style>
