<template>
<div class="app">
    <header>
        <div class="header-back">
            <div class="circle"></div>
        </div>

        <div class="header-slider">
            <el-carousel height="100%">
                <el-carousel-item v-for="item,i in slides" :key="i">
                    <div class="slide-content">
                        <div class="slide-text">
                            <div class="slide-text-cont">
                                <h3>{{item.h3}}</h3>
                                <h1>{{item.h1}}</h1>
                                <p>{{item.p}}</p>
                                <a @click="$router.push({'path': item.link})" class="menu-btn slide-btn">Узнать больше</a>
                            </div>
                            <div class="back-text" v-html="item.back"></div>
                        </div>
                        <div class="slide-image" :style="'background: url(\'./img/'+item.img+'\') no-repeat center center /contain'"></div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </header>
    <section id="plus-margin">
        <div class="container">
            <div class="zagl">
                <h2>Наши приемущества</h2>
                <div class="back-text h2">Top-it это</div>
            </div>
            <div class="awards-holder">
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="far fa-thumbs-up"></i></div>
                    <div class="award-icon">
                        <i class="far fa-thumbs-up"></i>
                    </div>
                    <div class="award-header">Качество</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="far fa-clock"></i></div>
                    <div class="award-icon">
                        <i class="far fa-clock"></i>
                    </div>
                    <div class="award-header">Оперативность</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="fas fa-hand-holding-usd"></i></div>
                    <div class="award-icon">
                        <i class="fas fa-hand-holding-usd"></i>
                    </div>
                    <div class="award-header">Доступность</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="fas fa-clipboard-check"></i></div>
                    <div class="award-icon">
                        <i class="fas fa-clipboard-check"></i>
                    </div>
                    <div class="award-header">Надежность</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="fas fa-shield-alt"></i></div>
                    <div class="award-icon">
                        <i class="fas fa-shield-alt"></i>
                    </div>
                    <div class="award-header">Безопасность</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="fas fa-map-signs"></i></div>
                    <div class="award-icon">
                        <i class="fas fa-map-signs"></i>
                    </div>
                    <div class="award-header">Многозадачность</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="fas fa-user-tie"></i></div>
                    <div class="award-icon">
                        <i class="fas fa-user-tie"></i>
                    </div>
                    <div class="award-header">Профессионализм</div>
                </div>
                <div class="award-wrapper">
                    <div class="award-icon-fon"><i class="fas fa-award"></i></div>
                    <div class="award-icon">
                        <i class="fas fa-award"></i>
                    </div>
                    <div class="award-header">Прозрачность</div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="flex">
                <div class="flex-image" style="background: url('./img/ab.png') no-repeat center center / contain"></div>
                <div class="flex-text">
                    <h2>Создаем удобные IT-решения</h2>
                    <p>Мы непонаслышке знаем насколько важны различные IT-сервисы в жизни каждой компании. Мы в Top-it стремимся создать максимально-комфортные условия для каждого клиента. Доверив задачу нам, вы можете быть уверены, в том что получите качественное решение.</p>
                    <a :href="'tel:'+phoneLink" class="call-us">
                        <i class="fas fa-phone-volume"></i>
                        <div class="call-text">
                            <p style="font-size: 18px">Остались вопросы? С радостью ответим!</p>
                            <a class="phone-link" style="font-weight: 700; margin: 0 0 0 0" :href="'tel:'+phoneLink">{{phone}}</a>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="dark">
        <div class="container">
            <div class="zagl">
                <h2>Направления деятельности</h2>
                <div class="back-text h2">Услуги</div>
            </div>
            <div class="ways-holder">
                <a v-for="way in ways" :key="way.text" @click="$router.push({'path':way.link})" class="ways-wrapper">
                    <div class="ways-image" :style="'background: url(\'./img/'+way.image+'\') no-repeat center center / cover'"></div>
                    <div class="ways-icon-text">
                        <div class="ways-icon">
                            <i :class="way.icon"></i>
                        </div>
                        <div class="ways-text">{{
                        way.text}}</div>
                    </div>
                </a>

            </div>
        </div>
    </section>
    <Contacts />
    <Reviews />
</div>
</template>

<script>
import Contacts from '../components/Contacts.vue'

import Reviews from '../components/Reviews.vue'
import con from '../constatnts'
export default {
    mounted() {
        scrollTo(0,0);
        document.title = "TOP-it - удобные IT-решения"
    },
    components: {  Contacts, Reviews },
    data() {
        return {
            ways: [{
                    'image': 's11.jpg',
                    'icon': 'fas fa-laptop',
                    'text': 'IT-аутсорсинг',
                    'link': '/outsorsing'
                },
                {
                    'image': 's2.jpg',
                    'icon': 'fab fa-internet-explorer',
                    'text': 'Web-разработка',
                    'link': '/sites'
                },
                {
                    'image': 's3.jpg',
                    'icon': 'fas fa-shield-alt',
                    'text': 'Информационная безопасность',
                    'link': '/infobez'
                }
            ],
            phone: con.phone,
            phoneLink: con.phoneLink,
            slides: [{
                    "h3": 'Обслуживаем',
                    "h1": 'IT-аутсорсинг',
                    "p": 'Мы предоставляем услуги ИТ-аутсорсинга в Тюмени. Вы передаете нашим специалистам обслуживание ИТ-инфраструктуры Вашей компании, а взамен получаете высокое качество сервиса по выгодной стоимости и оперативное решение задач. Больше нет необходимости содержать штатных ИТ-специалистов',
                    "link": '/outsorsing',
                    "back": 'Качество <br>Скорость<br>Цена',
                    "img": 's1.png'
                },
                {
                    "h3": 'Создаем',
                    "h1": 'Web-разработка',
                    "p": 'Создаем сайты любого масштаба: визитки, лэндинги, копроративные порталы, интернет-магазины. Вы сообщаете нам свои пожелания - мы воплощаем их в кратчайшие сроки, по фиксированной цене.',
                    "link": '/sites',
                    "back": 'Качество <br>Скорость<br>Цена',
                    "img": 'its.png'
                },
                {
                    "h3": 'Защищаем',
                    "h1": 'Информационная безопасность',
                    "p": 'Проведем аудит, расскажем про слабые места, устраним возможные точки входа в информационную систему предприятия. Так же можем рассказать чем занимаются сотрудники, выявить возможные пути утечки информации',
                    "link": '/outsorsing',
                    "back": 'Качество <br>Скорость<br>Точность',
                    "img": 's1.png'
                }
            ]
        }
    },

}
</script>

<style>
.ways-wrapper {
    cursor: pointer;
}

.phone-link {
    color: white;
    font-size: 20px;
}

.el-carousel {
    height: 100%;
}

.slide-content {
    margin: 0 auto;
}
@media (max-height: 600px){
    .slide-text-cont h3 {
        opacity: 0;
    }
}
</style>
