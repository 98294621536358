<template>
<section class="dark">
    <div class="container">
        <div class="zagl">
            <h2>Отзывы</h2>
            <div class="back-text h2">Отзывы</div>
        </div>
        <el-carousel class="reviews" :interval="4000" :type="type">
            <el-carousel-item v-for="item,i in reviews" :key="i">
                <div class="review-wrapper">
                    <div v-if="item.avatar === ''" class="avatar"> {{item.name.slice(0,1)}}</div>
                    <div class="avatar" v-else :style="'background: url(\'./img/reviews/'+item.avatar+'\') no-repeat center center /cover'"> </div>
                    <div class="rev-name">{{item.name}}</div>
                    <div class="rev-date">{{item.date}}</div>
                    <div class="rev-text">{{item.textPrint}}</div>
                    <p @click="showReview(i)" class="readMore" v-if="item.readMore">Читать отзыв полностью</p>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
    <el-dialog :visible.sync=dialog>
        <div class="review-wrapper">
            <div v-if="dialogData.avatar === ''" class="avatar"> {{dialogData.name.slice(0,1)}}</div>
            <div class="avatar" v-else :style="'background: url(\'./img/reviews/'+dialogData.avatar+'\') no-repeat center center /cover'"> </div>
            <div class="rev-name">{{dialogData.name}}</div>
            <div class="rev-date">{{dialogData.date}}</div>
            <div class="rev-text">{{dialogData.text}}</div>
        </div>
    </el-dialog>
</section>
</template>

<script>
export default {
    data() {
        return {
            dialogData: {
                name: 'Игорь Орлов',
                date: '09 января 2020',
                avatar: '1.jpg',
                text: 'Заказывал сайт-визитку. Сделали за три дня. Очень доволен',
            },
            dialog: false,
            type: 'card',
            reviews: [{
                    name: 'Игорь Орлов',
                    date: '09 января 2020',
                    avatar: '1.jpg',
                    text: 'Заказывал сайт-визитку. Сделали за три дня. Очень доволен',
                    textPrint: '',
                    readMore: false,
                },
                {
                    name: 'Людмила Кармацких',
                    date: '20 октября 2020',
                    avatar: '2.jpg',
                    text: 'Хочу выразить огромную благодарность сотруднику Too-it Антону, который предложил креативную новую идею по созданию и наполнению сайта с возможностью дальнейшего расширения. Сам подсказал, как сделать его наиболее функциональным, удобным. Обладает отличным вкусом. И все это по адекватной цене! Планирую пользоваться услугами этой компании в дальнейшем, а также буду рекомендовать друзьям. Молодцы!!',
                    textPrint: '',
                    readMore: false,
                },
                {
                    name: 'Олег Иванов',
                    date: '08 февраля 2021',
                    avatar: '3.jpg',
                    text: 'Могу порекомендовать данную за компанию за отличное сотрудничество. Пользуюсь услугами аутсорсинга у этих ребят на протяжении года и меня всё более чем устраивает, быстрый подход к делу, решение любых нестандартных вопросов и адекватные цены.',
                    textPrint: '',
                    readMore: false,
                },
                {
                    name: 'Наталья Дементьева',
                    date: '05 апреля 2021',
                    avatar: '',
                    text: 'Очень довольна работой Антона и Владислава уже второй год, а именно скоростью работы. На мой взгляд оперативность и скорость решения проблемы (что то сломалось и компьютер не работает или не устанавливается та или иная программа) системных администраторов играет важную роль работы в любой компании! Рекомендую убедиться в моем отзыве в реальной жизни, заключив с данной компанией договор на обслуживание!👍',
                    textPrint: '',
                    readMore: false,
                },
            ]
        }
    },
    mounted() {
        if (window.innerWidth < 825) {
            this.type = ''
        }
        if (window.innerWidth < 400) {
            for (let i = 0; i < this.reviews.length; i++) {
                if (this.reviews[i].text.length > 80) {
                    this.reviews[i].textPrint = this.reviews[i].text.slice(0, 80) + '...';
                    this.reviews[i].readMore = true;
                } else {
                    this.reviews[i].textPrint = this.reviews[i].text
                }
            }
        } else {

            for (let i = 0; i < this.reviews.length; i++) {
                if (this.reviews[i].text.length > 150) {
                    this.reviews[i].textPrint = this.reviews[i].text.slice(0, 150) + '...';
                    this.reviews[i].readMore = true;
                } else {
                    this.reviews[i].textPrint = this.reviews[i].text
                }
            }
        }
    },
    methods: {
        showReview(i) {
            this.dialog = true;
            this.dialogData.avatar = this.reviews[i].avatar;
            this.dialogData.text = this.reviews[i].text;
            this.dialogData.date = this.reviews[i].date;
            this.dialogData.name = this.reviews[i].name;

        }
    },
}
</script>

<style>
@media (max-width: 500px) {
    .el-dialog{
        width: 90%!important;
    }
}
.el-dialog {
    background-color: #1e3552;
}

.readMore {
    font-size: 16px;
    text-decoration: underline;
    transition: all .2s;
}

.readMore:hover {
    color: #007bff;
}

.rev-name {
    color: white;
    font-size: 20px;
    margin: 10px 0 0;
    text-align: center;
}

.rev-date {
    color: rgb(179, 179, 179)
}

.review-wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;

}

.rev-text {
    color: white;
    text-align: center;
    font-style: italic;
    margin-top: 10px;
    word-wrap: unset;
}

.avatar {

    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.reviews {
    width: 100%;
    margin-top: 40px;
}

.reviews .el-carousel__item {
    background-color: #1e3552;
}

.el-dialog__body {
    word-break: unset;
    padding-top: 0 !important;
}
</style>
