<template>
<div class="app">
    <Menu />
    <router-view></router-view>
    <Footer />
</div>
</template>

<script>
import Footer from './components/Footer.vue'
import Menu from './components/Menu.vue';
export default {
    components: { Footer, Menu },
   
         mounted() {
        let menu = document.getElementById('menu');
        let menuOn = 0;
        window.onscroll = function () {
            window.addEventListener('scroll', function () {

                if (pageYOffset > 150) {
                    menu.classList.add('menu-blue');
                    menu.classList.remove('menu-transp');
                    menuOn = 1;
                }
                if (menuOn === 1) {
                    if (pageYOffset < 150) {
                        menu.classList.remove('menu-blue');
                        menu.classList.add('menu-transp');
                        menuOn = 0
                    }
                }

            })
        }
    },
}
</script>

<style>
/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    padding: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    scroll-behavior: smooth !important;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    line-height: 1.5;
    font-family: 'Sofia Pro', sans-serif;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
    margin-top: 1em;
}

html {
    padding: 0;

}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

body {
    background: #182C44;
}

header {

    height: 100vh;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.header-back {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}

.circle {
    background: url("./assets/img/silidercircle.png") no-repeat left top/ contain;
    height: 500px;
    position: absolute;
    top: -30px;
    left: -30px;
    width: 100%;
    animation: chil 4s infinite;
}

.header-topline {
    z-index: 1000;
    position: fixed;
    padding: 60px 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.main-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.menu-item {
    margin: 0 20px;
    font-size: 20px;
    font-weight: 400;
    transition: all .2s ease-in-out;
    cursor: pointer;
    color: white;
}

.menu-item:hover {
    color: #007bff;
}

.menu-btn {
    border: none;
    display: block;
    font-size: 17px;
    margin-left: 30px;
    font-weight: 700;
    text-transform: capitalize;
    background-color: #005aff;
    border-radius: 3px;
    color: #fff;
    padding: 10px 25px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.menu-btn span {
    color: white;
}

.menu-btn:hover {
    background-color: #00142f;

}

.header-slider {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.logo-text {
    color: white;
    font-size: 30px;
    font-weight: 700;

}

.logo {
    display: flex;
    text-decoration: none;
    align-items: center;
}

span {
    color: #007bff;
}

.logo-img {
    background: url("./assets/img/logo.png") no-repeat center center / contain;
    height: 35px;
    width: 35px;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./assets/img/slidershapebd.png") no-repeat center center / cover;
}

.slide-content {
    max-width: 1400px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide:nth-child(even) .slide-content {
    flex-direction: row-reverse;
}

.swiper-slide:nth-child(even) .slide-text-cont {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.swiper-slide:nth-child(even) .back-text {
    text-align: right;
}

.slide-text,
.slide-image {
    display: flex;
    flex: 1 1 300px;
}

.slide-image {
    margin-top: 150px;
    height: 100%;
    width: 120%;
}

.slide-text {
    flex-direction: column;
    position: relative;
}

@keyframes chil {
    50% {
        transform: translateY(20px);
    }
}

h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 400;
}

h1 {
    font-size: 70px;
    text-transform: capitalize;
    color: #fffefe;
    margin-bottom: 20px;
    line-height: 1.4;
}

h2 {
    font-size: 40px;
    color: white;
    text-align: center;
}

p {
    margin: 15px 0;
    color: #fff;
    font-size: 20px;
}

.slide-btn {
    width: fit-content;
    margin: 20px 0;

}

.back-text {
    position: absolute;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 3;
    font-weight: 700;
    font-size: 145px;
    line-height: 150px;
    -webkit-text-stroke: 1px #fff;
    -webkit-text-fill-color: transparent;
    opacity: 0.07;
}

.slide-text-cont {
    position: relative;
    z-index: 5;
}

.zagl {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 50px auto;
}

.h2 {
    margin-left: 0;
    margin-top: -60px;
}

.award-icon i {
    font-size: 80px;
    color: white;
}

.award-icon-fon i {
    font-size: 190px;
    color: #83c2ff;
    opacity: 0;
    transition: all .2s;
}

.award-header {
    color: white;
    font-size: 24px;
    margin-top: 40px;
    font-weight: 700;
}

.award-wrapper {

    flex: 1 1 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 10px;
    background: #15263b;
    padding: 80px 20px;
    transition: all .2s;
}

.award-wrapper:hover {
    background: #007bff;
}

.award-wrapper:hover .award-icon-fon i {
    opacity: 0.1;
}

.awards-holder {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.award-icon-fon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.flex-image {
    flex: 1 1 300px;
    height: 500px;
    animation: chil 5s infinite;
}

.flex-text {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.call-us {
    display: flex;
    text-decoration: none;
    width: 100%;
    align-items: center;
}

.fa-phone-volume {
    padding: 11px 17px;
    background: #007bff;
    color: white;
    font-size: 40px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-right: 30px;
    box-shadow: 0px 0px 30px 0px rgba(148, 146, 245, 0.2);
}

.dark {
    background: #112032;
    padding: 50px 0;
}

.ways-holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;

}

.ways-wrapper {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    background: #182c44;
    margin: 10px;
    transition: all .2s;
}

.ways-wrapper:hover {
    background: #00142f;
}

.ways-image {
    height: 300px;
    width: 100%;
}

.ways-icon-text {
    width: 100%;
    padding: 0 40px;
    min-height: 120px;
    display: flex;
    align-items: center;
}

.ways-text {
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.ways-icon i {
    font-size: 40px;
    color: white;
    margin-right: 20px;
}

.ways-icon {
    display: flex;
    align-items: center;
}

.menu-blue {
    background: rgba(0, 90, 255, 0);
    animation: shown .7s forwards;

}

@keyframes shown {

    100% {

        padding: 10px 100px;
        background: rgba(0, 90, 255, 0.74)
    }
}

@keyframes shownBack {
    100% {

        padding: 60px 100px;
        background: rgba(0, 90, 255, 0)
    }
}

.menu-transp {
    padding: 30px 100px;
    animation: shownBack .7s forwards;
    background: rgba(0, 90, 255, 0.74)
}

.news {
    padding: 50px 0;
}

.new-image {
    flex: 1 1 300px;
    height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-decoration: none;
    transition: all .2s;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.71);
    z-index: 3;
}

.overlay-blue {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 90, 255, 0.8);
    z-index: 5;
    animation: grow;
    transform: scale(0);
    transition: all .2s;
}

.overlay-back {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 90, 255, 0.8);
    z-index: 5;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 20px;
}

.new-image:hover .overlay-blue {
    transform: scale(1);
}

.swiper-news {
    margin-top: 50px;
}

.new-content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}

.new-block {
    display: flex;
    align-items: center;
    padding: 5px;
}

.new-image-small {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.new-descr {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-clamp: 6;
    box-orient: vertical;
    font-size: 18px;
    color: white;
    margin-top: 20px;
}

.new-title {
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.new-category {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    display: inline-block;
}

.new-date {
    font-size: 17px;
    color: white;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin: 70px auto;
}

.col-4 {
    flex: 1 1 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px
}

.col-4 p {
    margin: 5px 0;
    text-align: center;
}

.col-4 i {
    margin-right: 10px;
}

.contact {
    background: url("./assets/img/fous.jpg") no-repeat center center / cover;
    padding: 50px 0;
}

.btn-send {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
}

.form input {
    color: black;
    border-radius: 5px;
    padding: 10px;
    border: none;
}

.form {
    text-align: center;
}

.header-services {
    height: 50vh;

}

.header-overlay {
    background: rgba(24, 44, 68, 0.62);
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* весь контейнер поддерживает перспективу */
.flip-container {
    perspective: 1000px;
    flex: 1 1 300px;
    margin: 10px;

    justify-content: center;
}

/* переверните сторону при проведении мышью */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container:hover .front,
.flip-container.hover .front {
    animation: transp .3s forwards;
}

@keyframes transp {
    100% {
        opacity: 0;
    }
}

.flip-container,
.front,
.back {

    height: 430px;
    width: 100%;
}

/* здесь находится скорость перевертывания */
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
}

/* скройте обратную сторону во время переворота */
.front,
.back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    padding: 50px 20px;
}

/* лицевая сторона размещена над обратной */
.front {
    z-index: 2;
    background: #005aff;
}

/* обратная, изначально скрытая сторона */
.back {
    transform: rotateY(180deg);
}

.flipper i {
    color: white;
    font-size: 50px;
}

.flipper-h {
    font-weight: 700;
    font-size: 27px;
    line-height: 27px;
    margin: 20px 0;
}

.flipper-p {
    font-size: 18px;
    margin: 0;
    padding-right: 10px;
}

.front .flipper-p {
    position: absolute;
    top: 180px;
}

.flipper-btn {
    position: absolute;
    bottom: 50px;
    color: white;
    font-size: 15px;
    padding: 8px 33px;
    border-radius: 30px;
    border: 2px solid white;
    width: fit-content;
    transition: all .2s;
    cursor: pointer;
}

.flipper-btn:hover {
    background: white;
    color: #007bff;
}

.prices-holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px 0;
    justify-content: center;
    align-items: center;
}

.price-wrapper {
    flex: 1 1 300px;
    display: flex;
    align-items: center;
    padding: 40px;
    flex-direction: column;
    border: 1px solid #005aff;
    border-radius: 5px;
    margin: 10px;
    min-height: 500px;
    position: relative;
    transition: all .2s;
}

.price-wrapper:hover {
    background: #00142f;
    transform: scale(1.1);
}

.price-wrapper .fas {
    font-size: 40px;
    color: white;
    
}

.plan-name {
    font-size: 24px;
    color: white;
    text-align: center;
    margin: 26px 0 12px 0;
}

.plan-price {
    font-size: 24px;
    font-weight: 700;
    color: white;
    margin-bottom: 30px;
    text-align: center;
}

.plan-descr {
    color: white;
    text-align: center;
}

.plan-buy {
    background: #005aff;
    line-height: 41px;
    padding: 0 30px;
    font-size: 16px;
    border-radius: 30px;
    color: #fff;
    border: 1px solid #005aff;
    font-weight: 600;
    bottom: 30px;
    position: absolute;
    transition: all .2s;
    cursor: pointer;
}

.plan-buy:hover {
    background: transparent;
}

.slider-container {
    margin-top: 50px;
    height: 300px;
    width: 100%;
}

.slider-container .swiper-slide {
    background: transparent;
    transition: all .2s;
}

.slider-container .swiper-slide:hover {
    transform: scale(1.1);
}

.outsource-calc {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#comp-num {
    width: 600px;
}

.burger-menu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}

.burger-menu .close-button {
    top: 40px;
}

.burger-button {
    display: none;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 100px;
    cursor: pointer;
}

.burger-button i {
    font-size: 30px;
    color: white;
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    font-size: 30px;
    color: black;
    transition: all .2s ease-in-out;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding-bottom: 2px;
    z-index: 9999;
}

.close-button:hover {
    background: #007bff;
    color: white;
    transform: rotate(360deg);
}

.burger-menu .menu-item {
    color: #333333;
    margin-bottom: 10px;
}

.burger-menu .menu-btn {
    margin-left: 0;
}

.popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.42);
    justify-content: center;
    align-items: center;
    display: none;
    animation: grow .6s;
}

@keyframes grow {
    0% {
        transform: scale(0);
    }

    70% {
        transform: scale(1.1);
    }

    80% {
        transform: scale(1);
    }

    90% {
        transform: scale(1.05);
    }
}

.popup-back {
    background: white;
    padding: 50px;
    position: relative;
}

.popup-back input {
    background: rgba(51, 51, 51, 0.09);
    margin: 5px;
}

.popup-back p {
    color: #333333;
    text-align: center;
    font-size: 24px;
}

.contact-descr {
    color: white;
    font-size: 20px;
    text-align: center;
}

.popup-close {
    animation: close 1s forwards;

}

@keyframes close {
    60% {
        transform: rotateX(360deg);
    }

    100% {
        transform: translateX(4000px);
    }
}

@media (max-width: 1400px) {
    .slide-content {
        padding: 10px;
    }
}

@media (max-width: 1120px) {
    .main-menu {
        display: none;
    }

    .burger-button {
        display: flex;
    }

    .back-text {
        font-size: 100px;
    }
}

@media (max-width: 750px) {
    .back-text {
        font-size: 70px;
    }

    .header-topline {
        padding: 20px 20px !important;
    }

    .col-4 {
        align-items: center;
        text-align: center;
    }

    .container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .slide-image {
        display: none;
    }

    .slide-text {
        padding: 10px;
    }

    h1 {
        font-size: 50px;
    }
}
@media (max-width: 420px) {
    .btn-send{
        width: 180px!important;
    }
}
@media (max-width: 480px) {
    .back-text {
        font-size: 50px;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    h2 {
        font-size: 32px;
    }

    h1 {
        font-size: 40px;
    }

    .swiper-news-next,
    .swiper-news-prev {
        display: none;
    }

    .ways-text {
        font-size: 20px;
    }

}

@media (max-width: 361px) {
    .back-text {
        font-size: 40px;
    }

    h1 {
        font-size: 32px;
    }

    .ways-text {
        font-size: 15px;
    }

    .header-slider .swiper-container {
        height: auto;
        margin: 50px 0;
    }

    #plus-margin {
        margin-top: 100px;
    }
}

a {
    text-decoration: none;
}

.back .flipper-btn {
    display: none;
}

.overlay-back {
    justify-content: start;
}

footer .menu-item {
    margin: 5px 0;
}
</style>
