import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import {Carousel,
  CarouselItem, Button, Input, Form, Dialog, Loading} from 'element-ui'

Vue.config.productionTip = false
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

