<template>
<div class="app">
    <header class="header-services" style="background: url('https://top-it72.ru/img/dev.jpg') no-repeat center center /cover fixed ">
        <div class="header-overlay">
            <h2>Web-разработка</h2>

        </div>
        <Menu />
    </header>
    <section class="dark">
        <div class="container">
            <div class="zagl">
                <h2>Что вы получите, заказав сайт у нас?</h2>
                <div class="back-text h2">Сайты</div>
            </div>
            <div class="flex" style="margin-top: 50px">
                <div class="flip-container" v-for="flip,i in flipers" :key="i">
                    <div class="flipper">
                        <div class="front">
                            <i :class="flip.frontIcon"></i>
                            <h3 class="flipper-h">{{flip.fronth}}</h3>
                            <p class="flipper-p">{{flip.frontp}}</p>
                            <div class="flipper-btn">Подробнее</div>
                        </div>
                        <div class="back" :style="'background: url(\'./img/'+flip.backimg+'\') no-repeat center center / cover'">
                            <div class="overlay-back">
                                <h3 class="flipper-h">{{flip.backh}}</h3>
                                <p class="flipper-p">{{flip.backp}}</p>
                                <div class="flipper-btn">Читать далее</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section style="padding-top: 50px">
        <div class="container">
            <div class="zagl">
                <h2>Цены на услуги</h2>
                <div class="back-text h2">Цены</div>
            </div>
            <div class="prices-holder">
                <div class="price-wrapper">
                    <i class="fas fa-paper-plane"></i>
                    <div class="plan-name">Одностраничник</div>
                    <div class="plan-price">от 10000₽</div>
                    <div class="plan-descr">Одностраничник он же лендинг - отличный способ рассказать о своих услугах и
                        привести новых клиентов!
                    </div>
                    <div @click="zakaz('Одностраничник')" class="plan-buy">Заказать</div>
                </div>
                <div class="price-wrapper">
                    <i class="fas fa-paper-plane"></i>
                    <div class="plan-name">Многостраничный сайт</div>
                    <div class="plan-price">от 25000₽</div>
                    <div class="plan-descr">В данную категорию входят различные каталоги, корпоративные порталы. Итоговая
                        стоимость зависит от количества страниц, функционала и структуры сайта.
                    </div>
                    <div @click="zakaz('Многостраничный сайт')" class="plan-buy">Заказать</div>
                </div>
                <div class="price-wrapper">
                    <i class="fas fa-paper-plane"></i>
                    <div class="plan-name">Интернет-магазин</div>
                    <div class="plan-price">от 45000₽</div>
                    <div class="plan-descr">Сайт для ведения электронной коммерции. Стоимость зависит от количества товаров,
                        фильтров и требуемого функционала.
                    </div>
                    <div @click="zakaz('Интернет-магазин')" class="plan-buy">Заказать</div>
                </div>
            </div>
        </div>
    </section>
    <section class="dark">
        <div class="container">
            <div class="zagl">
                <h2>Примеры выполненных работ</h2>
                <div class="back-text h2">Портфолио</div>
            </div>

            <div class="works">
                <div class="work-holder" v-for="work in works" :key="work.link">
                    <div class="work-img" :style="'background: url(\'./img/'+work.image+'\') no-repeat center top / cover'">

                    </div>
                    <div class="work-h">
                        {{work.h}}
                    </div>

                    <el-button class="el-button menu-btn btn-send el-button--default" @click="openSite(work.link,work.h)">
                        Смотреть онлайн
                    </el-button>
                </div>
            </div>
        </div>
    </section>
    <Contacts />
    <el-dialog v-loading="fullscreenLoading" :title="title" :visible.sync="dialog" :fullscreen="true" height="1" class="frame">
        <iframe frameborder="false" :src="link">

        </iframe>
    </el-dialog>
    <el-dialog width="600px" :visible.sync="popup" :title="'Заказать услугу - ' + form.tariff">
        <el-form class="form-zakaz" ref="form" :model="form" label-width="220px">
            <el-form-item required label="Как к вам обращаться?">
                <el-input v-model="form.name"> </el-input>
            </el-form-item>
            <el-form-item required label="Номер телефона">
                <el-input v-model="form.phone"> </el-input>
            </el-form-item>
            <el-form-item label="Адрес эл. почты">
                <el-input v-model="form.mail"> </el-input>
            </el-form-item>
            <el-form-item label="Желаемое время для звонка">
                <el-date-picker v-model="form.dateTime" type="datetime">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="Комментарий">
                <el-input type="textarea" :rows="2" v-model="form.message">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button :loading="loading" class="plan-buy buy-out" @click="send">Отправить</el-button>
            </el-form-item>

        </el-form>
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios'
import Contacts from '../components/Contacts.vue';

export default {
    components: { Contacts },
    mounted() {
        scrollTo(0, 0)
         document.title = "TOP-it | Создать сайт в Тюмени"
    },
    methods: {
        openSite(link, h) {
            this.fullscreenLoading = true;
            this.title = h;
            this.link = link;
            this.dialog = true
            setTimeout(this.openPop, 1000);

        },
        openPop() {
            this.fullscreenLoading = false;

        },
        send() {
            this.loading = true;
            let data = new FormData;
            data.append('type', 'sites');
            data.append('name', this.form.name);
            data.append('dateTime', this.form.dateTime);
            data.append('message', this.form.message);
            data.append('tariff', this.form.tariff);
            data.append('phone', this.form.phone);
            data.append('mail', this.form.mail);
            axios.post('mailer.php', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(this.loading=false, this.popup=false)
        },
        zakaz(tariff) {
            this.form.tariff = tariff;
            
            this.popup = true;
        },
    },
    data() {
        return {
            loading: false,
            popup : false,
            form: {
                name: '',
                dateTime: '',
                message: '',
                tariff: '',
                phone: '',
                mail: ''
            },
            dialog: false,
            fullscreenLoading: false,
            link: '',
            title: '',
            works: [{
                    'image': '13.png',
                    'h': 'Сайт для рекламного агенства UVPrint',
                    'sh': 'Многостраничный сайт',
                    'link': 'http://uvp72.ru/',

                },
                {
                    'image': '12.png',
                    'h': 'Сайт для строительной компании ПМК СБИК',
                    'sh': 'Многостраничный сайт',
                    'link': 'https://pmk-sbik.ru/',

                },
                {
                    'image': '11.png',
                    'h': 'Сайт для адвоката Кармацких',
                    'sh': 'Многостраничный сайт',
                    'link': 'https://advokarm.ru/',

                },
                {
                    'image': '2.png',
                    'h': 'Сайт для мебельной компании Спутник Мебели',
                    'sh': 'Многостраничный сайт',
                    'link': 'https://sputnik-mebeli.ru/',

                },
                {
                    'image': '4.png',
                    'h': 'Сайт для магазина натуральной косметики YOLO',
                    'sh': 'Интернет-магазин',
                    'link': 'https://yolonatural.ru/',

                },
                {
                    'image': '5.png',
                    'h': 'Сайт охотничьего хозяйства Кунчурское',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://ohota-tyumen.ru/',

                },
                {
                    'image': '6.png',
                    'h': 'Сайт для автокемпинга Усадьба',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://72usadba.ru/',

                },
                {
                    'image': '8.png',
                    'h': 'Сайт для мебельной компании Квартет',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://mkkvartet.ru/',

                },
                {
                    'image': '1.png',
                    'h': 'Сайт для компании Jelly Shots',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://jellyshots72.ru/',

                },
                {
                    'image': '9.png',
                    'h': 'Сайт для строительной компании СМК ИТЭС',
                    'sh': 'Многостраничный сайт',
                    'link': 'https://smk-ites.ru/',

                },
                {
                    'image': '10.png',
                    'h': 'Сайт для компании АвтоФранс',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://avtofrans.ru/',

                },
                {
                    'image': '7.png',
                    'h': 'Сайт для компании СтальИнстрой',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://стальинстрой.рф/',

                },
                {
                    'image': '14.png',
                    'h': 'Сайт для компании Грузовичок',
                    'sh': 'Одностраничный сайт',
                    'link': 'https://грузовичок72.рф/',

                },
            ],
            flipers: [{
                    'frontIcon': 'far fa-clock',
                    'fronth': 'Разработка в сжатые сроки',
                    'frontp': 'Мы используем отработанные решения, что позволяет сократить срок разработки сайта. Запустим лендинг за 3 дня.',
                    'backimg': 'c1.jpg',
                    'backh': 'Точно в срок!',
                    'backp': 'Не в наших правилах тратить время на многочасовые совещания и согласования, мы просто берем и делаем. Гарантируем, что выполним все работы по ТЗ точно в срок.',
                },
                {
                    'frontIcon': 'fas fa-dollar-sign',
                    'fronth': 'Адекватные цены',
                    'frontp': 'Мы - не большая компания, с раздутым штатом. Это позволяет нам держать цена на минимальном уровне.',
                    'backimg': 'c2.jpg',
                    'backh': 'Фиксированная цена!',
                    'backp': 'А еще мы гарантируем что, цена, указанная в договоре окончательная, и не вырастет в процессе работы.',
                },
                {
                    'frontIcon': 'fas fa-mobile-alt',
                    'fronth': 'Адаптивные сайты',
                    'frontp': 'По статистике больше половины просмотров сайта происходит с мобильных устройств, именно поэтому все наши сайты адаптированы под смартфоны.',
                    'backimg': 'c3.jpg',
                    'backh': 'Любое разрешение!',
                    'backp': 'Гарантируем, что ваш сайт будет выглядеть превосходно на любом устройстве',
                },
                {
                    'frontIcon': 'fas fa-wrench',
                    'fronth': 'Гарантия на работы',
                    'frontp': 'Мы уверены в своей работе, поэтому предоставляем гарантию на предоставленные услуги. Устраним проблему в кратчайшие сроки.',
                    'backimg': 'c4.jpg',
                    'backh': 'Без отказов!',
                    'backp': 'Лишь 1% наших клиентов обращались к нам по гарантии. Во всех остальных случаях все работает безупречно!',
                },
                {
                    'frontIcon': 'fas fa-gift',
                    'fronth': 'Домен и хостинг в подарок',
                    'frontp': 'Бесплатно предоставим домен и хостинг при заказе многостраничного сайта.',
                    'backimg': 's2.jpg',
                    'backh': 'Бесплатно!',
                    'backp': 'Зарегистрируем и подключим к хостингу имя сайта на целый год абсолютно бесплатно!',
                },
                {
                    'frontIcon': 'fas fa-cogs',
                    'fronth': 'Расширенный функционал',
                    'frontp': 'Нужен калькулятор, опрос или что-то еще? Разработаем и воплотим!',
                    'backimg': 's3.jpg',
                    'backh': 'Без проблем!',
                    'backp': 'Реализуем любой алгоритм так, как вам нужно.',
                }

            ]
        }
    },

}
</script>

<style>
iframe {
    scrollbar-width: 0px;
}

iframe::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.el-dialog.is-fullscreen {
    width: 100% !important;
    margin: 0 !important;
}

.frame .el-dialog__body {
    margin: 0;
    padding: 0 !important;
}

.el-loading-mask {
    background: white;
    z-index: 9999;
}

.el-dialog__title {
    color: white;
    font-size: 20px;
}

.frame .el-dialog__body {
    height: 92%;
}

iframe {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    background: white;
}

.work-h {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    font-size: 25px;
    padding: 20px;
}

.works .btn-send {
    width: 90% !important;
    position: absolute;
    bottom: 20px;
    margin: 0 20px;
}

.work-img {
    height: 300px;
    width: 100%;
}

.works {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
}

.work-holder {
    background: #00142f;
    margin: 10px;
    flex: 1 1 500px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding-bottom: 60px;
    transition: all .3s;
}

.work-holder:hover {
    background: #012047;
}
</style>
